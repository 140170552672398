/**
 * filename: index.js
 *
 * This is the entry point script for the tall website
 */


import '@fontsource-variable/open-sans';
import '../scss/tall.scss';
import '../css/tall.css';
import * as bootstrap from 'bootstrap';



/*
 * this snippet detects high-res small screens and alters the scale ratio to make
 * bootstrap elements show properly
 * 
 * adapted from
 * https://coderwall.com/p/ikrswg/bootstrap-3-grid-system-and-hi-density-small-screens-detected-as-xs
 */
let scale = 1 / (window.devicePixelRatio || 1);
let content = `width=device-width, initial-scale=${scale}, minimum-scale=${scale}, shrink-to-fit=no`;
const viewport = document.querySelector('meta[name="viewport"]');
viewport.setAttribute('content', content);